@charset "utf-8";
/* CSS Document */
@font-face {
    font-family: 'themix';
    src: url('https://cdn.skauting.cz/fonts/TheMix_LT_400.eot');
    src: url('https://cdn.skauting.cz/fonts/TheMix_LT_400.eot? #iefix') format('embedded-opentype');
    src: url('https://cdn.skauting.cz/fonts/TheMix_LT_400.woff') format('woff'),
    url('https://cdn.skauting.cz/fonts/TheMix_LT_400.woff2') format('woff2'),
    url('https://cdn.skauting.cz/fonts/TheMix_LT_400.otf') format('opentype'),
    url('https://cdn.skauting.cz/fonts/TheMix_LT_400.svg#themix') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'themix';
    src: url('https://cdn.skauting.cz/fonts/TheMix_LT_400i.eot');
    src: url('https://cdn.skauting.cz/fonts/TheMix_LT_400i.eot? #iefix') format('embedded-opentype');
    src: url('https://cdn.skauting.cz/fonts/TheMix_LT_400i.woff') format('woff'),
    url('https://cdn.skauting.cz/fonts/TheMix_LT_400i.woff2') format('woff2'),
    url('https://cdn.skauting.cz/fonts/TheMix_LT_400i.otf') format('opentype'),
    url('https://cdn.skauting.cz/fonts/TheMix_LT_400i.svg#themix') format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'themix';
    src: url('https://cdn.skauting.cz/fonts/TheMix_LT_700.eot');
    src: url('https://cdn.skauting.cz/fonts/TheMix_LT_700.eot? #iefix') format('embedded-opentype');
    src: url('https://cdn.skauting.cz/fonts/TheMix_LT_700.woff') format('woff'),
    url('https://cdn.skauting.cz/fonts/TheMix_LT_700.woff2') format('woff2'),
    url('https://cdn.skauting.cz/fonts/TheMix_LT_700.otf') format('opentype'),
    url('https://cdn.skauting.cz/fonts/TheMix_LT_700.svg#themix') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'themix';
    src: url('https://cdn.skauting.cz/fonts/TheMix_LT_700i.eot');
    src: url('https://cdn.skauting.cz/fonts/TheMix_LT_700i.eot? #iefix') format('embedded-opentype');
    src: url('https://cdn.skauting.cz/fonts/TheMix_LT_700i.woff') format('woff'),
    url('https://cdn.skauting.cz/fonts/TheMix_LT_700i.woff2') format('woff2'),
    url('https://cdn.skauting.cz/fonts/TheMix_LT_700i.otf') format('opentype'),
    url('https://cdn.skauting.cz/fonts/TheMix_LT_700i.svg#themix') format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'skautbold';
    src: url('https://cdn.skauting.cz/fonts/skaut-bold-webfont.eot');
    src: url('https://cdn.skauting.cz/fonts/skaut-bold-webfont.eot? #iefix') format('embedded-opentype');
    src: url('https://cdn.skauting.cz/fonts/skaut-bold-webfont.woff') format('woff'),
    url('https://cdn.skauting.cz/fonts/skaut-bold-webfont.woff2') format('woff2'),
    url('https://cdn.skauting.cz/fonts/skaut-bold-webfont.otf') format('opentype'),
    url('https://cdn.skauting.cz/fonts/skaut-bold-webfont.svg#skautbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
body {
    font-family: themix, sans-serif;
}

.h1, h2 {
    font-family: skautbold, sans-serif;
    font-weight: normal;
    font-size: xxx-large;
    text-align: center;
    margin-top: 1rem;
}

.p {
    font-weight: 400;
    font-style: normal;
}

.pbold {
    font-weight: bold;
    font-style: normal;
}